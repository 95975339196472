import router from "./index";
// 页面加载进度条
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { removeToken } from "@/utils/auth";

router.beforeEach((to, from, next) => {
  if (to.path == "/login") {
    removeToken();
  }
  document.title = '青峰见财讯';
  NProgress.start();
  next();
  // var token = getToken();
  // console.log(token)
  // if (!token && to.path != "/login") {
  //   next({ path: "/login" });
  // } else {
  //   NProgress.start();
  //   next();
  // }
});
router.afterEach(() => {
  NProgress.done();
});
