import Vue from "vue";
import router from "vue-router";
Vue.use(router);

export const routes = [
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/home.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login.vue"),
  },
  {
    path: "/college",
    name: "college",
    component: () => import("@/views/college.vue"),
  },
  {
    path: "/collegeDetails",
    name: "collegeDetails",
    component: () => import("@/views/collegeDetails.vue"),
  },
  {
    path: "/details",
    name: "details",
    component: () => import("@/views/details.vue"),
  },
  {
    path: "/acount",
    name: "acount",
    component: () => import("@/views/acount.vue"),
  },
  {
    path: "/collection",
    name: "collection",
    component: () => import("@/views/collection.vue"),
  },
  {
    path: "/buyed",
    name: "buyed",
    component: () => import("@/views/buyed.vue"),
  },
  {
    path: "/follow",
    name: "follow",
    component: () => import("@/views/follow.vue"),
  },
  {
    path: "/notice",
    name: "notice",
    component: () => import("@/views/notice.vue"),
  },
  {
    path: "/order",
    name: "order",
    component: () => import("@/views/order.vue"),
  },
  {
    path: "/problem",
    name: "problem",
    component: () => import("@/views/problem.vue"),
  },
  {
    path: "/subscription",
    name: "subscription",
    component: () => import("@/views/subscription.vue"),
  },
  {
    path: "/protocol",
    name: "protocol",
    component: () => import("@/views/protocol.vue"),
  },
  {
    path: "/refresh",
    name: "refresh",
    component: () => import("@/views/refresh.vue"),
  },
  {
    path: "/transfer",
    name: "transfer",
    component: () => import("@/views/transfer.vue"),
  },
  { path:'/' ,redirect:'/home' },
  { path:'*' ,redirect:'/home' }
];

export default new router({
  mode:'history',
  routes: routes,
});